.spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;

  z-index: 9999;

  opacity: 0;
  background-color: rgba(0, 0, 0, 0);

  animation: Spinner-reveal 10000ms forwards;
}

@keyframes Spinner-reveal {
  0% {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
  }
  5% {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    opacity: 1;
    background-color: rgba(0, 0, 0, .4);
  }
}

.spinnerSubcontainer {
  width: 100%;
  text-align: center;
  color: #1677ff;
  font-size: 64px;
}

