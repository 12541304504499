.markdown li p {
  margin: 0;
}

.markdown li {
  margin: 10px 0;
}

.markdown ul, .markdown ol {
  margin-bottom: 20px;
  padding: 0 40px;
}

.markdown ul {
  list-style: disc;
}

.markdown ol {
  list-style: decimal;
}

.markdown p {
  margin: 20px 0;
}

.markdown p:first-child {
  margin-top: 0;
}

.markdown p:last-child {
  margin-bottom: 0;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  margin-top: 2em;
  margin-bottom: 1em;
}

.markdown h1:first-child,
.markdown h2:first-child,
.markdown h3:first-child,
.markdown h4:first-child,
.markdown h5:first-child,
.markdown h6:first-child {
  margin-top: 0;
}

.markdown h1:hover .anchor,
.markdown h2:hover .anchor,
.markdown h3:hover .anchor,
.markdown h4:hover .anchor,
.markdown h5:hover .anchor,
.markdown h6:hover .anchor {
  opacity: 1;
}

.anchor {
  margin-left: 5px;
  opacity: 0;
}

.anchor:link,
.anchor:visited,
.anchor:hover,
.anchor:active {
  color: inherit;
}

.markdown code {
  white-space: pre-wrap;
  word-wrap: anywhere;
  max-width: 100%;
  padding: 2px 4px;
  border-radius: 7px;
  background-color: #e6f7ff;

  color: rgba(0, 0, 0, .65);
  font-family: inherit; /* To avoid browser to change the default font */
}


.markdown pre {
  overflow: inherit;
  margin: 20px 0;
}

.markdown pre > code::after {
  content: '';
  display: block;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #f15f59;
  box-shadow: 0 0 0 2px #f15f59, 1.3em 0 0 2px #febb2b, 2.6em 0 0 2px #4bb749;
}

.markdown pre > code::before {
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 38px;
  border-radius: 7px 7px 0 0;
}

.markdown pre > code {
  position: relative;
  display: block;
  background-color: #2D2B52;
  border-radius: 7px;
  padding: 16px;
  padding-top: 48px;
  color: #f0f0f0;
  margin-bottom: 45px;
  /* Set tab to 2 spaces else it is too wide */
  tab-size: 2;
}

/* Useful for documentation, when header is the first item */
.markdown blockquote:first-child {
  margin: 0;
}

.markdown blockquote {
  padding: 20px;
  margin: 40px 0;
  border-radius: 7px;
}

.markdown blockquote svg {
  font-size: 32px;
  margin-right: 16px;
}

.markdown blockquote div:first-child {
  display: flex;
  align-items: center;
}

.blockquote_default {
  background: rgba(38, 148, 255, .1);
  border: 1px solid rgba(38, 148, 255, .8);
  border-left: 0.25em solid rgba(38, 148, 255, .8);
}

.blockquote_default svg {
  color: rgb(38, 148, 255);
}


.blockquote_warning {
  background: rgba(255, 158, 0, .1);
  border: 1px solid rgba(255, 158, 0, .8);
  border-left: 0.25em solid rgba(255, 158, 0, .8);
}

.blockquote_warning svg {
  color: rgb(255, 158, 0);
}


.blockquote_alert {
  background: rgba(242, 12, 12, 0.1);
  border: 1px solid rgba(242, 12, 12, .8);
  border-left: 0.25em solid rgba(242, 12, 12, .8);
}

.blockquote_alert svg {
  color: rgb(242, 12, 12);
}


.markdown blockquote p {
  margin: 0;
}

.markdown blockquote code {
  background-color: rgba(255, 255, 255, .8);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
}


.markdown table {
  min-width: 500px;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  border-collapse: separate;
  border-spacing: 0;
  margin: 10px auto 40px auto;

  box-shadow: rgba(20, 20, 20, 0.27) 1px 1px 20px 0px;
}

.markdown th,
.markdown td {
  padding: 20px;
  line-height: initial;
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
}

.markdown th {
  border-top-style: none;
}

.markdown th:first-child,
.markdown td:first-child {
    border-left-style: none;
}

.markdown tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, .02);
}

.markdown strong {
  font-weight: 700;
}

.markdown img {
  display:block;
  margin: 60px auto 12px auto;

  max-width: 80%;
  max-height: 800px;

  box-shadow: rgba(20, 20, 20, 0.27) 1px 1px 10px 0px;

  overflow: hidden;
  border-radius: 7px;
}

.imgLegend {
  display: block;
  margin-bottom: 40px;
  text-align: center;
  font-size: 0.9em;
  font-style: italic;
}

@media only screen and (max-width: 768px) {
  .markdown table {
    min-width: 0;
  }
}