body {
  background-color: #f0f2f5 !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* To scroll smoothly to hash anchors */
  scroll-behavior: smooth;

  word-wrap: break-word;

  font-feature-settings: "tnum";
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-size: 14px;
  font-variant: tabular-nums;
}


a {
  text-decoration: none;
}