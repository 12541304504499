.menu {
  display: flex;
  font-size: 15px;
  white-space: nowrap;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 52px;

  background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
  box-shadow: rgba(0, 0, 0, .2) 0px 2px 4px;

  z-index: 1;
  color: #fff;
}



.menuContainer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.centered {
  max-width: 1200px;
  margin: 0 auto;
}


.logo {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.logo > * {
  vertical-align: middle;
}

.logo > img {
  margin-right: 6px;
}

.logo:hover, .logo:focus {
  color: #fff;
}

.logoLegend {
  font-size: 15px;
  font-weight: 400;
}

.spacer {
  flex: 1;
}

.sandwichButton {
  display: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  margin: 0;
  cursor: pointer;

  /* To give some space to click on it */
  padding: 10px 20px;
}

.menuSublinksContainer {
  /* To separate elements */
  margin: 0 5px;
}

.menuSublinksContainerSelectable {
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  transition: 250ms;
}

.menuSublinksContainerSelectable:hover {
  border-bottom: 2px solid rgba(255, 255, 255, .8);
}

.menuSublinksContainerSelected {
  border-bottom: 2px solid rgba(255, 255, 255, 1) !important;
}

.menuSublinksContainerSelected > * {
  color: rgba(255, 255, 255, 1) !important;
}

.menuSublinksContainer > * {
  /* To give some space to click on them */
  display: inline-block;
}

.menuSublinksContainer:first-child {
  margin-left: 0;
}

.menuSublinksContainerCallToAction {
  margin-right: 0;

  /* Align vertically */
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuSublinks {
  display: flex;
}

.menuSublinksContainer > a, .menuSublinksContainer button {
  /* To give some space to click on them */
  padding: 10px 20px;
}

.menuSublinksContainer a,
.menuSublinksContainer button {
  color: rgba(255, 255, 255, .8);
}

.menuSublinksContainer:hover a,
.menuSublinksContainer:hover button {
  color: rgba(255, 255, 255, 1);
}

.menuSublinksContainer > .menuCallToAction {
  padding: 6px 30px;
}

.menuCallToAction {
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff !important;
  transition: 250ms;
  background-color: transparent;
  cursor: pointer;
}

.menuCallToAction:hover {
  background-color: rgba(255, 255, 255, .1)
}



.serviceLogo {
  height: 20px;
  width: 20px;
  margin-right: 10px;

  display: inline-block;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.menuSublinksContainerService {
  display: none;
}


@media only screen and (min-width: 960px) {
  .menuSublinks {
    background: transparent !important;
  }
}

@media only screen and (max-width: 980px) {
  .menuContainer {
    padding: 0 10px;
  }

  .menuContainerDocumentations .logo {
    text-align: center;
    flex: 1;
  }

  .menuContainerDocumentations .spacer {
    flex: none;
  }

  .logo {
    font-size: 20px;
  }

  .sandwichButton {
    display: block;
    font-size: 20px;
  }

  .menuSublinks {
    position: fixed;
    /* Bellow the menu bar */
    top: 52px;
    left: 0;
    right: 0;
    bottom: 0;

    background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    box-shadow: inset rgba(0, 0, 0, .2) 0px 2px 4px;

    overflow-y: auto;

    font-size: 20px;

    flex-direction: column;

    opacity: 0;
    max-height: 0;
    transition: opacity 50ms ease-in, max-height 0s 50ms;
  }

  .menuSublinksContainerServices {
    display: none;
  }

  .menuSublinksContainerService {
    display: block;
  }

  .menuSublinksContainerServiceFirst {
    border-top: 20px solid rgba(255, 255, 255, .2);
  }

  .menuSublinksVisible {
    opacity: 1;
    /* should be way higher that real height */
    max-height: 3000px;
    transition: opacity 250ms ease-out, max-height 0s;
  }

  .menuSublinks > .menuSublinksContainer {
    margin: 0;
    padding: 5px 0;
    border-bottom: 1px solid rgba(255, 255, 255, .2) !important;
  }

  .menuSublinksContainer a,
  .menuSublinksContainer button {
    color: rgba(255, 255, 255, 1) !important;
  }

  .menuSublinksContainer > *,
  .menuSublinksContainer > .menuCallToAction {
    display: block;
    border: none;
    border-radius: 0;
    padding: 10px 20px;
  }

  .menuCallToAction:hover {
    background-color: inherit;
  }

  .menuSublinksContainerCallToAction {
    display: block;
  }

  .menuContainerDocumentation > .logo {
    flex: 1;
    text-align: center;
  }

  .menuContainerDocumentation > .logo > img {
    display: none;
  }

  .menuContainerDocumentation > .spacer {
    display: none;
  }
}
