.form > label {
  display: block;
  margin-bottom: 20px;
}

.cardInput {
  margin-top: 5px;
  padding: 0 11px;

  height: 32px;
  width: 100%;

  border: 1px solid #d9d9d9;
  border-radius: 4px;

  background-color: #fff;

  transition: all .3s;
}
