.avatarContainer {

}

.avatar {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ccc;
  width: 40px;
  height: 40px;

  color: #fff;

  display: flex;
  align-items: center;
  text-align: center;
}

.avatar svg, .avatar span {
  flex: 1;
  font-size: 24px !important;
}

.avatar div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}