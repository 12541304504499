.overlay {
  position: fixed;
  inset: 0;
  z-index: 1000;

  background-color: rgba(0, 0, 0, .45);

  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.modalContainer {
  position: fixed;
  z-index: 1001;
  width: 100%;
  inset: 0;

  color: #555555;
  white-space: initial;
  font-size: 14px;
}

.alert {
  padding: 15px;
  border: 1px solid #91d5ff;
  border-radius: 2px;
  background-color: #e6f7ff;
  margin-bottom: 20px;
}

.modal {
  position: relative;
  top: 100px;
  width: 520px;
  max-width: 100%;
  margin: 0 auto;

  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px #0000001f,0 6px 16px #00000014,0 9px 28px 8px #0000000d;

  animation: fadeIn 0.1s ease-out;
}

.modalHeader {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;

  padding: 16px 24px;
  border-bottom: 1px solid #f0f0f0;

}

.modalContent {
  padding: 24px;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 20px;
  color: #555;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.inputContainer > span {
  padding-left: 10px;
  padding-right: 4px;
}

.inputContainer > input {
  flex: 1;
  border: none;
  padding: 4px 11px 4px 0;
}

.error {
  border-color: #ff0000 !important;
}


.inputContainer input:focus {
  border: none;
  outline: 0;
}

.inputContainer:focus-within {
  border-color: #40a9ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.inputContainer:hover {
  border-color: #40a9ff !important;
}

.modalFooter {
  padding: 10px 16px;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}

.button {
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
  box-shadow: 0 2px #00000004;
  cursor: pointer;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
}

.button:disabled, .button:disabled:hover {
  color: #00000040;
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

.buttonCancel {
  color: #000000d9;
  border-color: #d9d9d9;
  background: #fff;
}

.buttonCancel:hover {
  color: #40A9FF;
  border-color: #40A9FF;
}

.buttonOk {
  color: #fff;
  border-color: #1677ff;
  background: #1677ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px #0000000b;

  margin-left: 8px;
}

.buttonOk:hover {
  color: #fff;
  border-color: #40A9FF;
  background-color: #40A9FF;
}
