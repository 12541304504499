.contentSticked {
  position: sticky;
  top: 52px;
  z-index: 1;

  box-shadow: rgba(0, 0, 0, .2) 0px 2px 4px;
}

.contentCentered {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .contentCentered {
    padding: 10px;
  }
}