.button {
  color: #ffffff;
  font-size: 14px;

  text-align: center;
  text-decoration: none;

  font-weight: 500;
  padding: 5px 12px;

  border: none;

  display: inline-block;

  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);

  cursor: pointer;

  transition: all 250ms;
  transform: scale(1);
}

.buttonSizeBig {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 32px;
}

.buttonSizeSmall {
  font-weight: 500;
  padding: 0px 12px;
}

.button:hover {
  transform: scale(1.1);
}