.entryContainer {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.title {
  font-weight: 600;
}

.required {
  color: #f5222d;
}

.helpBefore {
  margin-bottom: 20px;
}

.helpAfter {
  margin-top: 20px;
}

.entryContainer .entryContainer {
  display: flex;
  align-items: baseline;
  border-bottom: 0;
  padding-bottom: 0;
}

.entryContainer .entryContainer:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.entryContainer .entryContainer .title {
  padding-right: 10px;
}

.entryContainer .entryContainer .title:after {
  content: ': '
}

.entryContainer .entryContainer .helpAfter {
  margin-top: 10px;
}

.inputNumber {
  width: 100%;
  max-width: 160px;
}

.inputNumber input {
  text-align: right !important;
}

@media only screen and (max-width: 1200px) {
  .title {
    margin-bottom: 20px;
  }

  .content {
    padding-left: 20px;
  }

  .entryContainer .entryContainer {
    flex-direction: column;
  }

  .entryContainer .entryContainer .title {
    text-align: left;
  }
}
