.dropdownContainer {
  display: inline;
  position: relative;
}

.dropdownButton {
  display: inline-block;
  text-align: center;
  background-color: transparent;
  white-space: nowrap;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
}

.dropdownContent {
  position: absolute;
  min-width: 100px;
  z-index: 2;

  opacity: 0;
  max-height: 0;

  color: #555;
  background-color: #fff;
  background-clip: padding-box;

  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);

  overflow: hidden;

  transition: opacity 50ms ease-in, max-height 0s 50ms;
}

.dropdownContentDisplayed {
  opacity: 1;
  max-height: 3000px; /* should be way higher that real height */
  transition: opacity 250ms ease-out, max-height 0s;
}

.dropdownContentLeft {
  left: 0;
}

.dropdownContentRight {
  right: 0;
}

.dropdownContent > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  column-gap: 0;
  column-count: 3;
}


@media only screen and (max-width: 980px) {
  .dropdownContent > ul {
    column-count: 2;
  }
}

@media only screen and (max-width: 768px) {
  .dropdownContent > ul {
    column-count: 1;
  }
}
