.container {
  font-size: 16px;
}


.container h2,
.container h3,
.container h4,
.container h5,
.container h6 {
  font-weight: 700;
}

.container h1 {
  font-weight: 700;
  font-size: 21px;
  border-bottom: 1px solid #eee;
}

.container h2 {
  font-size: 19px;
  border-bottom: 1px solid #eee;
}

.container h3 {
  font-size: 18px;
}

.container h4 {
  font-size: 17px;
}

.container h5 {
  font-size: 16px;
}

.container h6 {
  font-size: 16px;
}


.summary {
  margin: 30px 0 0 15px;
}

.summary * {
  font-size: 16px;
}