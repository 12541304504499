.container {
  width: 800px;
  max-width: 100%;

  margin: 60px auto;

  text-align: center;
  font-size: 24px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;

  padding: 30px 0;
}

.container > * {
  margin-bottom: 40px;
}

.icon {
  font-size: 72px;
  color: #faad14 !important;
}
