.code {
  white-space: pre-wrap;
  word-wrap: anywhere;
  max-width: 100%;
  padding: 2px 4px;
  border-radius: 7px;
  background-color: #e6f7ff;

  color: rgba(0, 0, 0, .65);
  font-family: inherit; /* To avoid browser to change the default font */
}