.container {
  font-size: 14px;
}

.container svg {
  font-size: 18px;
}

.container, .container a {
  color: rgba(255, 255, 255, .8);
}

.container ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.container li > :first-child {
  display: flex;
  align-items: center;

  transition: all 250ms;

  padding: 20px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  outline: none;
}

.container li:hover {
  background-color: rgba(255, 255, 255, .2);
}

.container ul ul {
  background-color: rgba(0, 0, 0, .5);
}

.container ul ul li {
  padding-left: 45px;
}

.liSelected {
  background-color: rgba(255, 255, 255, .1);
}

.liWithSubItems > button {
  display: flex;
  align-items: center;
}

.liWithSubItems > button > span:first-child {
  flex: 1;
  display: flex;
  align-items: center;
}

.liWithSubItems > button > span:nth-child(2) {
  transition: all 250ms;
}

.liWithSubItems > button > span:nth-child(2) > svg {
  font-size: 10px;
  margin: 0;
}

.liWithSubItemsOpened > button > span:nth-child(2) {
  transform: rotate(180deg);
}