.container {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .8) 50px, rgba(0, 0, 0, .6) 100%);
  padding: 70px 10px 10px 10px;
  font-size: 16px;
}

.container a, .container button {
  transition: 250ms;
  color:rgba(255, 255, 255, .8);
}

.container a:hover, .container button:hover {
  color: #fff;
}

.container h3 {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
}


.columns {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
  display: flex;
  align-items: top;
}


.column {
  margin: 0 20px;
  white-space: nowrap;
}

.column:last-child {
  flex: 1;
  text-align: center;
}

.services {
  display: flex;

  justify-content: space-between;
  flex-wrap: wrap;
}

.services a {
  display: block;
  width: 33%;

  margin: 5px 0;
  padding: 5px;

  white-space: nowrap;
  overflow: hidden;


  border: 1px solid transparent;
  border-radius: 3px;
}

.services a:hover {
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, .1);
}

/* Icon */
.services div {
  height: 30px;
  width: 30px;
  margin-right: 10px;

  display: inline-block;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  background-color: rgba(255, 255, 255, .2);
  border: 5px solid transparent;
  border-radius: 3px;
}

.services div, .services span {
  vertical-align: middle;
}

.otherContainer {
  display: inline-block;
  margin: 0 auto;
  padding: 0;
  text-align: left;
}

.otherContainer > li {
  padding: 5px 0;
  list-style-type: none;
}

.languagesAndRegions {
  margin-top: 20px;
  text-align: left;
  width: 100%;
}

.languagesAndRegionsButton {
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #fff;
  padding: 4px 15px;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.socials {
  font-size: 25px;
}

.socials a {
  margin: 0 10px;
}

.stackheroIs {
  max-width: 1000px;
  margin: 0 auto;

  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-align: center;
}

.legal {
  max-width: 1000px;
  margin: 0 auto;

  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, .5);
  text-align: center;
}

.legal a {
  color: rgba(255, 255, 255, .5);
}


@media only screen and (max-width: 768px) {
  .container {
    padding: 20px 10px 10px 10px;
  }

  .columns {
    display: block;
    margin-bottom: 10px;
  }

  .column {
    margin: 0 0 60px 0;
  }

  .services a {
    width: 50%;
  }
}