.menuLeft {
  position: sticky;
  top: 52px;
  left: 0;
  height: calc(100vh - 52px);
  flex: 0 0 300px;

  background-color: #0f172d;

  overflow-y: auto;
  box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, .1);

  transition: all 250ms;

  display: flex;
  flex-direction: column;
}

.menuLeftDisabled .menuLeft {
  display: none;
}

.menuLeft svg {
  margin-right: 10px;
}

.menuLeftSpacer {
  flex: 1;
}

.menuLeftFooter {
  padding: 10px 20px;
  background-color: rgb(4, 24, 54);
}

.menuLeftFooter > a {
  color: #fff;
}

.container {
  display: flex;
  padding-top: 52px;
}

.content {
  width: calc(100vw - 300px);
}

.menuLeftDisabled .content {
  width: 100%;
}

@media only screen and (max-width: 980px) {
  .menuLeft {
    flex: 0 0 250px;
  }

  .content {
    width: calc(100vw - 250px);
  }
}

@media only screen and (max-width: 768px) {
  .content {
    width: 100%;
  }

  .menuLeft {
    position: fixed;
    z-index: 2;
    /* Bellow the menu bar */
    top: 52px;
    left: 0;
    right: 0;
    bottom: 0;

    box-shadow: inset rgba(0, 0, 0, .2) 0px 2px 4px;

    height: auto;
    width: 100%;
    opacity: 0;
    max-height: 0;
    transition: opacity 50ms ease-in, max-height 0s 50ms;
  }

  .menuLeftOpened {
    opacity: 1;
    /* should be way higher that real height */
    max-height: 3000px;
    transition: opacity 250ms ease-out, max-height 0s;
  }
}