.routerTransitions {
  animation: fadeInFromNone 250ms ease-out;
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
