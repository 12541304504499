.contentContainer {
  padding-top: 52px;

  /* To avoid to have the footer on top while loading (and make lighthouse happy) */
  min-height: calc(100vh - 52px);
}

.contentContainerSizeLimited {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
}

.contentCard {
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .1);
  border-radius: 3px;
}

@media only screen and (max-width: 768px) {
  .contentContainer {
    padding: 10px;
    padding-top: 62px;
  }
}