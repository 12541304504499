.card {
  margin: 10px;
  cursor: initial;
}

.strongLegend {
  font-weight: 700;
  font-size: 16px;
}

.subLegend {
  font-size: 12px;
}

.diviver {
  margin: 10px 0;
}