.li {
  padding: 0;
  line-height: 22px;
  font-size: 14px;
  border-radius: 4px;
}

.li > * {
  display: block;
  padding: 10px 12px;
  white-space: nowrap;
}

.li > button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  color: #555 !important;
  width: 100%;
  text-align: left;
}

.li > button:hover, .li > a, .li > a:hover {
  color: #555 !important;
}

.li:hover {
  background-color: #f5f5f5;
}