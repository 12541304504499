/* Avoid icons to be bold */
.breadcrumb {
  margin-bottom: 20px;
}

.breadcrumb svg {
  font-weight: normal;
}


@media only screen and (max-width: 768px) {
  .breadcrumb {
    display: none;
  }
}