.container {
  height: 600px;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
  padding: 5px;
}

.container samp {
  font-family: monospace; /* not the default one to display correctly QR codes. Useful with Node-RED and Whatsapp bot node */
  white-space: pre-wrap;
}

.container samp p {
  margin: 0;
  line-height: 1;
  word-break: break-all;
}

.container samp p span {
  color: #aaa;
}

.container samp p:hover {
  background-color: #123;
}