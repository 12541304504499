.link div {
  height: 20px;
  width: 20px;
  margin-right: 10px;

  display: inline-block;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}


.link div, .link span {
  vertical-align: middle;
}