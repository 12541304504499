.radioVertical {
  display: block;
  margin-bottom: 20px;
  margin-right: 0;
}

.radioVertical:last-child {
  margin-bottom: 0;
}

.radioLegend {
  margin-top: 5px;
  margin-left: 24px;
  white-space: initial;
  color: #999;
  display: inline-block;
}