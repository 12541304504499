.items {
  padding-top: 20px;
}

.item {
  border-left: 1px solid #1677ff;
  padding: 20px 0;
  padding-left: 20px;
  margin-bottom: 40px;
}

.item button {
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .item {
    padding-left: 20px;
  }
}