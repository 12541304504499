.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }
}